import React from 'react';

const Livestream = () => {
    return (
        <div>
            <h2>Live Stream</h2>
            <video
                width="640"
                height="360"
                controls
                autoPlay
            >
                <source src="https://cdn.flowplayer.com/a30bd6bc-f98b-47bc-abf5-97633d4faea0/hls/de3f6ca7-2db3-4689-8160-0f574a5996ad/playlist.m3u8" type="application/x-mpugurl" />
                {/* For RTMP: <source src="https://cdn.flowplayer.com/a30bd6bc-f98b-47bc-abf5-97633d4faea0/hls/de3f6ca7-2db3-4689-8160-0f574a5996ad/playlist.m3u8" type="application/x-mpugurl" /> */}
                {/* For WebRTC, you might need a more complex setup involving a WebRTC library */}
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default Livestream;
